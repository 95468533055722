<template>
  <div>
    <b-modal  :id="$route.name + 'CreateModal'" title="Добавить" size="sm" hide-footer no-enforce-focus  :no-close-on-backdrop="true" >
        <b-row>
            <b-col cols="6">
                <label>Добавить</label>
                <b-input placeholder="Наименование"
                 style="width: 350px;" 
                 type="text"
                 v-model="promoType.name"
                 />
                 
            </b-col>
        </b-row>
        <b-row class="mb-1 mt-2">
            <b-col class="d-flex justify-content-between">
                <b-button @click="close()"  variant="danger">Отмена</b-button>
                <b-button
                @click="saveType"
                variant="primary"
                >
                    Добавить
                </b-button>
            </b-col>
        </b-row>
    </b-modal>
  </div>
</template>

<script>
import ToastificationContent from '@/@core/components/toastification/ToastificationContent.vue';
export default {
    components: {
        ToastificationContent
    },
  data(){
    return {
        promoType :{
            name:null
        } 
    }
  },
  methods : {
    close() {
        this.$bvModal.hide(this.$route.name + 'CreateModal')
        this.promoType = {
            name:null
        } 
    },
    saveType(){
        this.$http
        .post('types-promocodes', this.promoType)
        .then(res => {
            this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props : {
                    title: 'Успех!',
                    icon: 'CheckIcon',
                    variant: 'success',
                    text: `Тип добавлен`
                }
            })
            this.$emit('refresh')
            this.$bvModal.hide(this.$route.name + 'CreateModal')
        })
        .catch(err => {
            this.$toast({
                component : ToastificationContent,
                position: 'top-right',
                props: {
                    title: 'Ошибка!',
                    icon: 'XIcon',
                    variant: 'danger',
                    text: `Некоректные данные` 
                }
            })
        })
    }
  }
}
</script>

<style>

</style>